.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container-vertical {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.myId {
    margin-top: 2rem;
    border-radius: 5px;
    background: #ffffff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.call-button {
    text-align: center;
    margin-top: 2rem;
}

.video-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video {
    position: relative;
    width: 100%;
    height: 100%;
}

.caller {
    text-align: center;
    color: #ec0000;
}

.divider {
    margin-bottom: 80px;
}

body {
    background: #ffffff;
}