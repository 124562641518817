.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container-vertical {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.header-top {
    background-color: #f5f9fb;
    color: #fe0000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    height: 40px;
    margin-right: 10px;
}

.header-text {
    display: flex;
    align-items: center;
    text-align: center;
    flex-grow: 1;
}

.header-text h1 {
    margin: 0;
}

.logout-container {
    color: #ffffff;
    cursor: pointer;
    margin-left: auto;
    padding-right: 10px;
}

.logout-container:hover {
    color: #f0f4f8;
}

body {
    background: #ffffff;
}