.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.video {
    width: 100%;
    margin-bottom: 1rem;
}

.caller {
    text-align: center;
    color: #ec0000;
    margin-top: 2rem;
}

.divider {
    margin-bottom: 20px;
}

body {
    background: #ffffff;
}